import React from "react";

function Products() {
  return (
    <div>
      <div className="header-base">
        <div className="container">
          <div className="row">
            <div className="col-md-9">
              <div className="title-base text-left">
                <h1>Slot Drain Channel</h1>
                {/* <p>Success is no accident. It is hard work, perseverance, studying, sacrifice and most of all, love of what you are doing or learning to do.</p> */}
              </div>
            </div>
            <div className="col-md-3">
              <ol className="breadcrumb b white">
                <li>
                  <a href="#">Products</a>
                </li>
                <li className="active">Slot Drain Channel</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="section-empty " style={{ backgroundColor: "white" }}>
        <div className="container content">
          <div className="row">
            <div className="col-md-8">
              <div
                className="flexslider slider nav-inner white row-17"
                data-options="numItems:3,controlNav:true,itemMargin:60"
              >
                <ul className="slides">
                  <li>
                    <a
                      className="img-box lightbox"
                      href="./template/images/products/slot drain.jpg"
                      data-lightbox-anima="fade-top"
                    >
                      <img
                        src="./template/images/products/slot drain.jpg"
                        alt=""
                        style={{
                          objectFit: "contain",
                          width: "850px",
                          height: "950px",
                        }}
                      />
                    </a>
                  </li>

                  {/* <li>
                    <a
                      className="img-box lightbox"
                      href="./template/images/products/products_3.jpg"
                      data-lightbox-anima="fade-top"
                    >
                      <img
                        src="./template/images/products/products_3.jpg"
                        alt
                      />
                    </a>
                  </li> */}
                  <li>
                    <a
                      className="img-box lightbox"
                      href="./template/images/products/products_3_1.jpg"
                      data-lightbox-anima="fade-top"
                    >
                      <img
                        src="./template/images/products/products_3_1.jpg"
                        alt
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      className="img-box lightbox"
                      href="./template/images/products/products_3_2.jpg"
                      data-lightbox-anima="fade-top"
                    >
                      <img
                        src="./template/images/products/products_3_2.jpg"
                        alt
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      className="img-box lightbox"
                      href="./template/images/products/products_3_3.jpg"
                      data-lightbox-anima="fade-top"
                    >
                      <img
                        src="./template/images/products/products_3_3.jpg"
                        alt
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      className="img-box lightbox"
                      href="./template/images/products/products_3_4.jpg"
                      data-lightbox-anima="fade-top"
                    >
                      <img
                        src="./template/images/products/products_3_4.jpg"
                        alt
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4 boxed white row-17">
              <h2 className="text-color">Description</h2>
              <hr className="space m" />
              <p>
                Our slot drain channels offer sleek and modern drainage
                solutions for contemporary spaces. With a discreet and stylish
                design, they seamlessly integrate into any environment while
                ensuring effective water removal and easy cleaning.
              </p>
              {/* <hr className="space s" /> */}
              {/* <a href="#" className="btn-text text-color"> Visit website</a> */}
            </div>
          </div>
          <hr className="space" />
          <h2>Specification</h2>
          <hr className="space m" />
          <ul>
            <li>- According to EN 1253-2:2015</li>
            <li>- Designed and optimised for use in commercial.</li>
            <li>- Available along with gratings for load classes up</li>
            to C 250 (EN 1253).
            <li>- Mesh grating sleep resistant as per DIN 51130.</li>
            <li>- Suitable for vinyl, tiled, concrete and resin floors.</li>
            <li>
              - Slot Drain Channel can be fully customised as per the site
              requirements. The size of Slot Drain Channels are specified by
              (Slot Width X Length X Height) along with Outlet Diameter.
            </li>
          </ul>

          <div class="row">
            <img
              className="col-md-6"
              src="./template/images/products/products_3_type1.jpg"
              alt="products_3_type"
            />
            <img
              className="col-md-6"
              src="./template/images/products/products_3_type2.jpg"
              alt="products_3_type"
            />
          </div>

          <hr className="space m" />

          <hr className="space" />
          {/* <div className="row porfolio-bar">
                        <div className="col-md-2">
                            <div className="icon-box" data-anima="scale-up" data-trigger="hover">
                                <i className="fa fa-arrow-left text-m" /><label className="text-s"><a href="#">Previous</a></label>
                            </div>
                        </div>
                        <div className="col-md-8 text-center">
                            <a href="#" data-anima="scale-up" data-trigger="hover"><i className="fa fa-th anima" /></a>
                        </div>
                        <div className="col-md-2 text-right">
                            <div className="icon-box icon-box-right pull-right" data-anima="scale-up" data-trigger="hover">
                                <label className="text-s"><a href="#">Next</a></label><i className="fa fa-arrow-right text-m" />
                            </div>
                        </div>
                    </div> */}
        </div>
      </div>
    </div>
  );
}

export default Products;
